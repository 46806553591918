import {isNil} from 'lodash/fp';

export default class CashUtil {
  defaultCash(number?: number): number {
    if (isNil(number)) {
      return 0;
    }

    return number;
  }
}