import commandAccessService from 'command/commandAccessService';
import { ReactElement, useState, } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxLoader,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxFormikCashInput,
    NxSelect,
    NxInput,
    NxFormikNumberInput,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import useAxios from "axios-hooks";
import { HttpError } from "tools/HttpTypes";
import { Dictionary } from "dictionary/DictionaryType";
import { filterLocations } from "tools/GeolocationUtil";
import { useHistory } from "react-router";
import { CommandOutputWrapper } from 'command/CommandTypes';
import { useCommand } from 'command/CommandService';

interface CustomerIndividualProps {
    birthCountryId?: number | null;
    customerId: number;
    cocreeCustomer: any;
    dictionaryEnums: any;
}

const INCOME_INDICATOR = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "Annual", value: "ANNUAL" },
];

interface CustomerData {
    id?: number;
    customerId?: number;
    customerType?: string;
    birthProvinceId?: number | null;
    birthCityId?: number | null;
    numberOfDependents?: number | null;
    numberOfCarsOwned?: number | null;
    grossIncome: number;
    netIncome: number;
    incomeIndicator: string;
    occupationStatus: string;
    largeExposureIndicator: boolean | null | undefined;
}

const CustomerIndividualSchema = Yup.object().shape({
    customerId: Yup.number().optional(),
    customerType: Yup.string().optional(),
    birthProvinceId: Yup.number().optional().nullable(),
    birthCityId: Yup.number().optional().nullable(),
    numberOfDependents: Yup.number().optional().nullable().max(99),
    numberOfCarsOwned: Yup.number().optional().nullable().max(999),
    grossIncome: Yup.number()
        .required("Gross Income is required")
        .positive("Gross Income must be greater than 0")
        .typeError("Gross Income must be greater than 0"),
    netIncome: Yup.number()
        .required("Net Income is required")
        .positive("Net Income must be greater than 0")
        .typeError("Net Income must be greater than 0"),
    incomeIndicator: Yup.string().required(),
    occupationStatus: Yup.string().required(),
    largeExposureIndicator: Yup.boolean()
        .notRequired()
        .nullable()
        .test("is-valid", "Large Exposure Indicator is a required field", function (value) {
            return value === true || value === false;
        }),
});

const CustomerIndividual = ({ birthCountryId, customerId, cocreeCustomer, dictionaryEnums }: CustomerIndividualProps): ReactElement => {
    const OCCUPATION_STATUS = Object.entries((dictionaryEnums).OccupationStatus).map(([label, value]) => ({
        label: label,
        value: value
    }));
    const [{ data: dictionaries, loading: dictionariesLoading }] = useAxios<Dictionary[], HttpError>(
        "/dictionaries/geo/locations?minDepth=2&maxDepth=3&fetchNested=true&sorting=ALPHABETICAL"
    );
    const [{ data: countriesFetch, loading: countriesLoading }] = useAxios<Dictionary[], HttpError>(
        "/dictionaries"
    );
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();
    const COUNTRY_LIST = countriesFetch?.find(entry => entry.code === "COUNTRY")
    ?.entries
    const COUNTRY_PH = COUNTRY_LIST?.find(entry => entry.code === "PH")
    const MATCHED_USER_COUNTRY = COUNTRY_LIST?.find(entries => entries.id === birthCountryId)
    const DISPLAY_COUNTRY = birthCountryId
        ? MATCHED_USER_COUNTRY?.values[0]?.value
        : "No birth country set";

    const hasAccess = commandAccessService.canExecute("CreateCocreeCustomer") || commandAccessService.canExecute("UpdateCocreeCustomer");

    const execute = useCommand();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formInitValues = {
        birthProvinceId: cocreeCustomer.data?.birthProvinceId ?? null,
        birthCityId: cocreeCustomer.data?.birthCityId ?? null,
        numberOfDependents: cocreeCustomer.data?.numberOfDependents ?? null,
        numberOfCarsOwned: cocreeCustomer.data?.numberOfCarsOwned ?? null,
        grossIncome: cocreeCustomer.data?.grossIncome ?? 0,
        netIncome: cocreeCustomer.data?.netIncome ?? 0,
        incomeIndicator: cocreeCustomer.data?.incomeIndicator ?? "",
        occupationStatus: cocreeCustomer.data?.occupationStatus ?? "",
        largeExposureIndicator: cocreeCustomer.data?.id ? cocreeCustomer.data?.largeExposureIndicator : null,
    }

    return (
        <>
            {isLoading || dictionariesLoading || countriesLoading ? (
                <NxLoader />
            ) : (
                <NxFormik<CustomerData>
                    initialValues={formInitValues}
                    validationSchema={CustomerIndividualSchema}
                    onSubmit={async (values): Promise<void> => {
                        try {
                            setIsLoading(true);
                            const response: CommandOutputWrapper<void> = await execute<CustomerData, void>({
                                name: cocreeCustomer.data.id ? 'UpdateCocreeCustomer' : 'CreateCocreeCustomer',
                                input: {
                                    ...values,
                                    id: cocreeCustomer.data.id ?? null,
                                    customerId: customerId,
                                    customerType: 'INDIVIDUAL',
                                }
                            });

                            if (!response.approvalRequired) {
                                history.goBack();
                            }
                        } catch(err) {
                            console.error(err)
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                >
                    {({ isValid, values, isSubmitting, submitForm, handleChange, setFieldValue }): ReactElement => {
                        return (
                            <NxForm>
                                <NxStack>
                                    <NxInput disabled name="birthCountry" label="Birth Country" value={DISPLAY_COUNTRY} />

                                    {COUNTRY_PH?.id === birthCountryId ? (
                                        <>
                                            <NxSelect
                                                disabled={!hasAccess}
                                                onChange={(value) => {
                                                    setFieldValue("birthProvinceId", value);
                                                }}
                                                value={values.birthProvinceId}
                                                name="birthProvinceId"
                                                label="Birth Province"
                                                options={filterLocations(dictionaries).getProvinces()}
                                            />

                                            <NxSelect
                                                disabled={!hasAccess}
                                                onChange={(value) => {
                                                    setFieldValue("birthCityId", value);
                                                }}
                                                value={values.birthCityId}
                                                name="birthCityId"
                                                label="Birth City/Municipality"
                                                options={filterLocations(dictionaries).getCitiesForProvince(values.birthProvinceId!)}
                                            />

                                            <NxInput
                                                disabled
                                                name="birthPlace"
                                                label="Birth Place"
                                                value={`${filterLocations(dictionaries).getProvince(values.birthProvinceId!)?.name ?? ""} ${filterLocations(dictionaries).getCity(values.birthProvinceId!, values.birthCityId!)?.name ??
                                                    ""
                                                    }`}
                                            />
                                        </>
                                    ) : null}

                                    <NxFormikNumberInput disabled={!hasAccess} max={99} name="numberOfDependents" label="Number of Dependents" />

                                    <NxFormikNumberInput disabled={!hasAccess} max={999} name="numberOfCarsOwned" label="Number of car(s) owned" />

                                    <NxFormikCashInput disabled={!hasAccess} name="grossIncome" label="Gross income" />

                                    <NxFormikCashInput disabled={!hasAccess} name="netIncome" label="Net income" />

                                    <NxFormikSelect
                                        disabled={!hasAccess}
                                        name="incomeIndicator"
                                        label="Gross and Net income indicator"
                                        options={INCOME_INDICATOR}
                                    />

                                    <NxFormikSelect disabled={!hasAccess}  name="occupationStatus" label="Occupation Status" options={OCCUPATION_STATUS} />

                                    <NxFormikSelect
                                        disabled={!hasAccess}
                                        name="largeExposureIndicator"
                                        label="Large Exposure Indicator"
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                            { label: "", value: null },
                                        ]}
                                    />

                                    <NxRow>
                                        <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                                            Cancel
                                        </NxButton>
                                      {
                                        hasAccess && <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                        Save
                                        </NxButton>
                                      }
                                    </NxRow>

                                    <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    submitForm();
                                                    setShowSavePopup(false);
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>

                                    <NxPopup
                                        header="Confirm"
                                        open={showCancelPopup}
                                        description="Discard changes and go to previous screen?"
                                    >
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    setShowCancelPopup(false);
                                                    history.goBack();
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>
                                </NxStack>
                            </NxForm>
                        );
                    }}
                </NxFormik>
            )}
        </>
    );
};

export default CustomerIndividual;
