import { ReactElement, useState, useRef } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
    NxFormikDatePicker,
    NxFormikNumberInput,
    NxFormikPercentageInput,
    NxFormikInput,
    NxLoader,
    NxFormikCashInput,
    NxCashInput
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
import { CommandOutputWrapper } from 'command/CommandTypes';
import { useCommand } from 'command/CommandService';
import { repricingFrequency } from "constants/loan";
interface DebtEquityInvestment {
    id?: number;
    customerId?: number;
    branchId?: number;
    recordType?: string;
    providerContractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    amountGranted: number;
    outstandingBalance: number;
    estimatedCreditLossesAllowance: number;
    nominalInterestRate: number;
    probabilityOfDefault: number;
    effectiveInterestRate: number;
    unearnedInterest: number;
    interestSetting: string;
    daysPastDue: number;
    restructureCreditType?: string;
    guaranteeType?: string;
    exposureStatus: string;
    investmentType: string;
    accumulatedMarketGains: number;
    creditRatingAgencyUsed: string;
    creditRating: string;
    creditRatingLastIssuer: string;
    natureOfOffering: string;
    repricingFrequency?: string;
}

const DebtEquityInvestmentSchema = Yup.object().shape({
    providerContractNumber: Yup.string().required('Provider contract number field is required'),
    investmentType: Yup.string().required('Investment type field is required'),
    contractStartDate: Yup.date()
        .required('Contract start date is required')
        .typeError('Invalid date format')
        .max(Yup.ref('contractEndDate'), 'Must not be greater than Contract end date'),
    contractEndDate: Yup.date()
        .required('Contract end date is required')
        .typeError('Invalid date format')
        .min(Yup.ref('contractStartDate'), 'Must not be lesser than Contract start date'),
    amountGranted: Yup.number().required('Amount granted field is required').min(0, "Value should be a positive number").test('length', 'Maximum of 17 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 17
    ),
    accumulatedMarketGains: Yup.number().required('Accumulated market gains field is required').min(0, "Value should be a positive number").test('length', 'Maximum of 17 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 17
    ),
    outstandingBalance: Yup.number().required('Outstanding balance field is required').min(0, "Value should be a positive number").test('length', 'Maximum of 17 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 17
    ),
    estimatedCreditLossesAllowance: Yup.number().required('Estimated allowance for credit losses field is required').min(0, "Value should be a positive number").test('length', 'Maximum of 17 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 17
    ),
    nominalInterestRate: Yup.number().required('Nominal interest rate field is required').min(0, "Value should be a positive number"),
    probabilityOfDefault: Yup.number().required('Probability of default field is required').min(0, "Value should be a positive number"),
    effectiveInterestRate: Yup.number().required('Effective interest rate field is required').min(0, "Value should be a positive number"),
    unearnedInterest: Yup.number().required('Unearned interest field is required').min(0, "Value should be a positive number").test('length', 'Maximum of 17 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 17
    ),
    interestSetting: Yup.string().required('Interest setting field is required'),
    repricingFrequency: Yup.string().when('interestSetting', {
        is: 'REPRICEABLE',
        then: Yup.string().required('Repricing frequency is required'),
        otherwise: Yup.string().notRequired()
    }),
    daysPastDue: Yup.number().required('Days past due field is required').min(0, "Value should be a positive number").integer().test('length', 'Maximum of 4 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 4
    ),
    restructureCreditType: Yup.string().notRequired(),
    guaranteeType: Yup.string().notRequired(),
    creditRatingAgencyUsed: Yup.string().required('Credit rating agency used field is required'),
    creditRatingLastIssuer: Yup.string().required('Credit rating last issuer field is required'),
    creditRating: Yup.string().required('Credit rating field is required'),
    natureOfOffering: Yup.string().required('Nature of offering field is required'),
    exposureStatus: Yup.string().required('Exposure status field is required'),
});

interface ExposureDEIFormProps {
    hasAccess: boolean;
    exposureData: any;
    customerId: number;
    userBranchId: number;
    dictionaryEnums: any;
}

const ExposureDEIForm = ({ hasAccess, exposureData, customerId, userBranchId, dictionaryEnums }: ExposureDEIFormProps): ReactElement => {
    const [isDEIFormLoading, setIsDEIFormLoading] = useState<boolean>(false);
    const execute = useCommand();
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();
    const savedState = useRef<DebtEquityInvestment>();

    const formInitValues = {
        providerContractNumber: (exposureData?.providerContractNumber || savedState?.current?.providerContractNumber) ?? '',
        contractStartDate: (exposureData?.contractStartDate || savedState?.current?.contractStartDate) ?? '',
        contractEndDate: (exposureData?.contractEndDate || savedState?.current?.contractEndDate) ?? '',
        amountGranted: (exposureData?.amountGranted || savedState?.current?.amountGranted) ?? null,
        outstandingBalance: (exposureData?.outstandingBalance || savedState?.current?.outstandingBalance) ?? null,
        estimatedCreditLossesAllowance: (exposureData?.estimatedCreditLossesAllowance || savedState?.current?.estimatedCreditLossesAllowance) ?? null,
        nominalInterestRate: (exposureData?.nominalInterestRate || savedState?.current?.nominalInterestRate) ?? null,
        probabilityOfDefault: (exposureData?.probabilityOfDefault || savedState?.current?.probabilityOfDefault) ?? null,
        effectiveInterestRate: (exposureData?.effectiveInterestRate || savedState?.current?.effectiveInterestRate) ?? null,
        unearnedInterest: (exposureData?.unearnedInterest || savedState?.current?.unearnedInterest) ?? null,
        interestSetting: (exposureData?.interestSetting || savedState?.current?.interestSetting) ?? '',
        daysPastDue: (exposureData?.daysPastDue || savedState?.current?.daysPastDue) ?? null,
        restructureCreditType: (exposureData?.restructureCreditType || savedState?.current?.restructureCreditType) ?? '',
        guaranteeType: (exposureData?.guaranteeType || savedState?.current?.guaranteeType) ?? '',
        exposureStatus: (exposureData?.exposureStatus || savedState?.current?.exposureStatus) ?? '',
        investmentType: (exposureData?.investmentType || savedState?.current?.investmentType) ?? '',
        accumulatedMarketGains: (exposureData?.accumulatedMarketGains || savedState?.current?.accumulatedMarketGains) ?? null,
        creditRatingAgencyUsed: (exposureData?.creditRatingAgencyUsed || savedState?.current?.creditRatingAgencyUsed) ?? '',
        creditRating: (exposureData?.creditRating || savedState?.current?.creditRating) ?? '',
        creditRatingLastIssuer: (exposureData?.creditRatingLastIssuer || savedState?.current?.creditRatingLastIssuer) ?? '',
        natureOfOffering: (exposureData?.natureOfOffering || savedState?.current?.natureOfOffering) ?? '',
        repricingFrequency: (exposureData?.repricingFrequency || savedState?.current?.repricingFrequency) ?? '',
    };

    return (<>
        {isDEIFormLoading ? <NxLoader/> : (
            <NxFormik<DebtEquityInvestment>
                initialValues={formInitValues}
                validationSchema={DebtEquityInvestmentSchema}
                onSubmit={async (values): Promise<void> => {
                    try {
                        setIsDEIFormLoading(true)
                        if (values.repricingFrequency === "") {
                            delete values.repricingFrequency
                        }
                        if (values.restructureCreditType === "") {
                            delete values.restructureCreditType
                        }
                        if (values.guaranteeType === "") {
                            delete values.guaranteeType
                        }
                        const response: CommandOutputWrapper<void> = await execute<DebtEquityInvestment, void>({
                          name: exposureData.id ? 'UpdateExposure' : 'CreateExposure',
                          input: {
                            ...values,
                            id: exposureData.id ?? null,
                            customerId: customerId,
                            branchId: userBranchId,
                            recordType: 'DEBT_AND_EQUITY_INVESTMENT',
                          }
                        });

                        if (!response.approvalRequired) {
                          history.goBack();
                        }
                    } catch (err) {
                        savedState.current = values;
                        console.error(err)
                    } finally {
                      setIsDEIFormLoading(false)
                    }
                }}
            >
                {({ values,submitForm, setFieldValue, errors }): ReactElement => {
                    return (
                      <NxForm>
                        &nbsp;
                        <NxHeader>Debt & Equity Investment</NxHeader>
                        &nbsp;
                        <NxStack>
                          <NxFormikInput
                            disabled={exposureData?.reported || !hasAccess}
                            maxLength={38}
                            name="providerContractNumber"
                            label="Provider contract number"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="investmentType"
                            label="Interest setting"
                            options={Object.entries(dictionaryEnums.InvestmentType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikDatePicker
                            disabled={!hasAccess}
                            name="contractStartDate"
                            label="Contract start date"
                          />
                          <NxFormikDatePicker disabled={!hasAccess} name="contractEndDate" label="Contract end date" />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="amountGranted"
                            label="Amount granted"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="accumulatedMarketGains"
                            label="Accumulated market gains"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="outstandingBalance"
                            label="Outstanding balance"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="estimatedCreditLossesAllowance"
                            label="Allowance for estimated credit losses"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="nominalInterestRate"
                            label="Nominal interest rate"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="probabilityOfDefault"
                            label="Probability of default"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="effectiveInterestRate"
                            label="Effective interest rate"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            decimals={0}
                            currency=""
                            name="unearnedInterest"
                            label="Unearned interest"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="interestSetting"
                            label="Interest setting"
                            options={Object.entries(dictionaryEnums.InterestSetting).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          {values?.interestSetting === "REPRICEABLE" ? (
                            <NxFormikSelect
                              disabled={!hasAccess}
                              name="repricingFrequency"
                              label="Repricing Frequency"
                              options={JSON.parse(JSON.stringify(repricingFrequency))}
                            />
                          ) : null}
                          <NxCashInput
                            name="daysPastDue"
                            label="Days past due"
                            disabled={!hasAccess}
                            decimals={0}
                            currency=""
                            onChange={(value) => {
                              setFieldValue("daysPastDue", value)
                            }}
                            value={values.daysPastDue}
                            error={errors.daysPastDue}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="restructureCreditType"
                            label="Restructure credit type"
                            options={Object.entries(dictionaryEnums.RestructureCreditType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="guaranteeType"
                            label="Guarantee Type"
                            options={Object.entries(dictionaryEnums.GuaranteeType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRatingAgencyUsed"
                            label="Credit rating agency used"
                            options={Object.entries(dictionaryEnums.CreditAgency).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRating"
                            label="Credit rating issuer last investment"
                            options={Object.entries(dictionaryEnums.CreditRating).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRatingLastIssuer"
                            label="Last credit rating issuer"
                            options={Object.entries(dictionaryEnums.CreditRatingIssuer).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="natureOfOffering"
                            label="Nature of offering"
                            options={Object.entries(dictionaryEnums.OfferingNature).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="exposureStatus"
                            label="Exposure status"
                            options={Object.entries(dictionaryEnums.ExposureStatus).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />

                          <NxRow>
                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                              Cancel
                            </NxButton>
                            {hasAccess ? (
                              <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                Save
                              </NxButton>
                            ) : null}
                          </NxRow>

                          <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  submitForm();
                                  setShowSavePopup(false);
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>

                          <NxPopup
                            header="Confirm"
                            open={showCancelPopup}
                            description="Discard changes and go to previous screen?"
                          >
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  setShowCancelPopup(false);
                                  history.goBack();
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>
                        </NxStack>
                      </NxForm>
                    );
                }}
            </NxFormik>
        )}
    </>)
}

export default ExposureDEIForm